<template>
  <div class="streamer-canvas">
    <streaming-service />
    <div id="canvas" ref="canvasWrapper">
      <canvas ref="streamerCanvas"></canvas>
    </div>
    <canvas class="dotCanvas" ref="canvas"></canvas>
    <!-- <img class="logo" src="/assets/ce-season-logo.png" alt=""> -->
    <img class="logo new" src="/assets/ce-logo-2024.png" alt="">
    <!-- <img class="tagline" src="/assets/2024-tagline.png" alt=""> -->
  </div>
</template>

<script>

import streamingService from "../components/StreamingService.vue";
import _shuffle from "lodash/shuffle"
import DotCanvas from "../lib/DotCanvas.js"
import { Flock, Boid } from "../lib/Flocking.js"
import { RecordCanvasMixin } from "../lib/RecordCanvasMixin.js"

export default {
  mixins: [RecordCanvasMixin],
  components: {
    streamingService
  },
  data() {
    return {
      isMobile: window.innerWidth < 500,
      streamerPositions: [],
      cellWidth: 0,
      cellHeight: 0,
      glsl: false,
      dotCanvas: false,
      viewParams: {
        DPR: 1,
        canvasSize: new Float32Array(2)
      },
    }
  },
  computed: {
    streamers() {
      return this.$store.state.streamers || []
    },
    localStreamer() {
      return this.streamers.find(s => s.uid == this.$store.state.userData.UID)
    },
    videoAspectRatio() {
      return this.cellHeight / this.cellWidth
    }
  },
  watch: {
    streamers() {
      this.setStreamerPositions()
    }
  },
  mounted() {
    this.setStreamerPositions()
    this.startP5()
  },
  methods: {
    startSwiss(streamerCanvas) {
      const canvasEl = this.$refs.canvas
      const gl = canvasEl.getContext('webgl2', {alpha: false, antialias: true, xrCompatible: false})
      this.glsl = SwissGL(gl)
      this.dotCanvas = new DotCanvas(this.glsl, streamerCanvas)
    },
    swissFrame(frameCount) {
      this.glsl.adjustCanvas(1);
      this.viewParams.canvasSize.set([window.innerWidth, window.innerHeight]);

      this.dotCanvas.frame(this.glsl, {...this.viewParams});
    },
    setStreamerPositions() {
      const winWidth = window.innerWidth
      const winHeight = window.innerHeight
      let numRows = 1
      let numCols = 1
      if (this.streamers.length > 1) {
        if (winHeight > winWidth) {
          numCols = 2
        }
        else numRows = 2
      }
      if (this.streamers.length > 2) {
        numCols = 2
        numRows = 2
      }
      this.cellWidth = winWidth / numRows
      this.cellHeight = winHeight / numCols
      const positions = []
      for (let i = 0; i < numRows; i++) {
        for (let j = 0; j < numCols; j++) {
          const x = i * this.cellWidth
          const y = j * this.cellHeight
          positions.push({x, y})
        }
      }
      this.streamerPositions = _shuffle(positions)
    },
    fillVideoBuffer(buffer, streamer) {
      const {width: streamWidth, height: streamHeight} = streamer.videoTrack.getSettings()
      const streamAspectRatio = streamHeight / streamWidth
      let sx = 0, sy = 0, sWidth, sHeight, dx = 0, dy = 0, dWidth = this.cellWidth, dHeight = this.cellHeight
      if (streamAspectRatio <= this.videoAspectRatio) {
        //Video is wide, lock to height of video and crop sides
        const targetWidth = 1 / (this.videoAspectRatio * (1 / streamHeight))
        sx = (streamWidth - targetWidth) / 2
        sWidth = targetWidth
        sHeight = streamHeight
      }
      else {
        const targetheight = this.videoAspectRatio * streamWidth
        sy = (streamHeight - targetheight) / 2
        sWidth = streamWidth
        sHeight = targetheight
      }
      buffer.drawingContext.drawImage(streamer.el.firstChild, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
      return buffer
    },
    startP5() {

      let p5Runtime
      let flocks = {}
      let hasWords = {}
      const config = p => {
        let videoBuffer = p.createGraphics(this.cellWidth, this.cellHeight)
        let wordBuffer = p.createGraphics(p.windowWidth, p.windowHeight)

        p.windowResized = () => {
          p.resizeCanvas(p.windowWidth, p.windowHeight);
          this.isMobile = p.windowWidth < 500
        }

        p.setup = () => {
          p.createCanvas(p.windowWidth, p.windowHeight, p.WEBGL)
          p.frameRate(60)
          p.pixelDensity(1)
          p.setAttributes('antialias', false);
          p5Runtime = p
          this.startSwiss(p.canvas)
        }

        p.draw = () => {
          p.background(0, 0, 0)
          p.translate(-p.width/2, -p.height/2, 0)
          for (let i = 0; i < this.streamers.slice(0, 4).length; i++) {
            const pos = this.streamerPositions[i]
            if (pos){
              videoBuffer = this.fillVideoBuffer(videoBuffer, this.streamers[i])
              p.image(videoBuffer, pos.x, pos.y)
            }
          }

          // Draw Words
          wordBuffer.drawingContext.clearRect(0, 0, wordBuffer.canvas.width, wordBuffer.canvas.height);
          this.streamers.slice(0, 4).forEach(streamer => {
            const streamerId = streamer.uid
            const words = this.$store.state.words[streamerId] || []
            if (words.length) {
              this.$store.commit("clearStreamerWords", streamerId)
              if (!flocks[streamerId]) flocks[streamerId] = new Flock(wordBuffer)
              for (const i in words) {
                let b = new Boid(Math.random() * p.width, Math.random() * p.height, wordBuffer, words[i].toUpperCase(), "#000000");
                flocks[streamerId].addBoid(b);
              }
              hasWords[streamerId] = true
            }
            if (hasWords[streamerId]) flocks[streamerId].run()
          })

          p.image(wordBuffer, 0, 0)
        
          p.fill(p.mouseIsPressed ? "#ffffff" : "#000000")
          p.noStroke()
          if (p.mouseX && p.mouseY) p.circle(p.mouseX, p.mouseY, 80)
          this.swissFrame(p.frameCount)

          // If streamer is on canvas and a word is on canvas record it
          if (this.localStreamer && !this.canvasRecorded && hasWords[this.localStreamer.uid]) {
            let captureTracks = [this.localStreamer.audioTrack]
            captureTracks = captureTracks.concat(this.$refs.canvas.captureStream(30).getTracks())
            const captureStream = new MediaStream(captureTracks)
            this.recordingTimeout = setTimeout(() => this.recordCanvas(captureStream), 500)
            this.canvasRecorded = true
          }
        }

      }

      new p5(config, this.$refs.canvasWrapper)

    }
  }
}

</script>

<style lang="scss">

.streamer-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: none;
  #canvas {
    display: none;
  }
  .dotCanvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // filter: invert(1);
    // display: none;
  }
  .logo {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    padding: 10px;
    background-color: #000;
    box-sizing: border-box;
  }
  .tagline {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    filter: invert(1);
    padding: 4px;
    width: 60px;
    background: #000;
  }
  @media only screen and (max-width: 600px) {
    .logo {
      width: calc(100% - 20px);
      bottom: 10px;
    }
    .tagline {
      width: 40px;
      padding: 2px;
      left: 10px;
    }
  }
}

</style>