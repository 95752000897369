<template>
  <div :class="['streaming-service random', { 'featured-active': featured }]">
    <div class="connecting-container message-container" v-if="connecting">
      <div>connecting...</div>
    </div>
  </div>
</template>

<script>
// Mixins
import { WebSocketMixin } from "../lib/WebSocketMixin.js";
// Utils
import VideoFeed, { bus } from "../lib/VideoFeed.js";
import _random from "lodash/random";
import _debounce from "lodash/debounce";
import _shuffle from "lodash/shuffle";

export default {
  mixins: [WebSocketMixin],
  data() {
    return {
      videoFeed: false,
      videoEventBus: bus,
      videoEls: {},
      connecting: true,
      wsPeers: [],
      // streamingPeers: [],
      ringColors: _shuffle(["#1d95d1", "#ffd803", "#00993f", "#ffffff"]),
      frames: [],
      featured: false,
      winHeight: window.innerHeight,
      winWidth: window.innerWidth
    };
  },
  computed: {
    streamers() {
      return this.$store.state.streamers
    },
    isMobile() {
      return this.winWidth < 450;
    },
    circleRadius() {
      return this.isMobile ? 75 : 150;
    },
    streamerWidth() {
      if (this.isMobile) return 150;
      else return 300;
    },
    cellWidth() {
      return this.streamerWidth + 20
    },
    numRows() {
      return Math.floor((this.winHeight - 50 - this.cellWidth) / this.cellWidth)
    },
    numCols() {
      return Math.floor((this.winWidth - this.cellWidth) / this.cellWidth)
    },
    yOffset() {
      return (this.winHeight - 50 - ((this.numRows + 1) * this.cellWidth)) / 2
    },
    xOffset() {
      return (this.winWidth - ((this.numCols + 1) * this.cellWidth)) / 2
    }
  },
  async mounted() {
    if (screen.orientation.lock)
      screen.orientation.lock("portrait").catch(err => console.log(err));

    // From Mixin
    setTimeout(() => this.websocketSetup(), 1000);
    window.addEventListener('resize', _debounce(this.resetStreamerPositions, 100))
  },
  beforeDestroy() {
    this.videoFeed.leave();
    if (this.WSHandler) this.WSHandler.close()
    this.$store.commit("clearStreamers")
  },
  watch: {
    wsPeers() {
      this.$emit("update-peers", this.wsPeers);
    }
  },
  methods: {
    resetStreamerPositions() {
      this.winHeight = window.innerHeight
      this.winWidth = window.innerWidth
      this.$store.commit("clearStreamerPositions")
      for (let i = 0; i < this.$store.state.streamers.length; i++) {
        const pos = this.getStreamerPosition()
        this.$store.commit("setStreamerPosition", {uid: this.$store.state.streamers[i].uid, pos})
      }
    },
    resetRingColors() {
      this.ringColors = _shuffle(["#1d95d1", "#ffd803", "#00993f", "#ca2f2f"])
    },
    getStreamerPosition() {
      let pos = false
      let itr = 0
      while (!pos && itr < 100) {
        const candidate = {
          col: _random(0, this.numCols) * this.cellWidth,
          row: _random(0, this.numRows) * this.cellWidth
        }
        const isTaken = this.streamers.find(s => s.position.col > -1 && s.position.col == candidate.col && s.position.row == candidate.row)
        if (!isTaken) pos = candidate
        itr++
      }
      if (!pos) {
        pos = {
          col: -1,
          row: -1,
          x: _random(0, this.winWidth - this.streamerWidth),
          y: _random(0, this.winHeight - 50 - this.streamerWidth)
        }
      }
      else {
        pos.x = pos.col + _random(0, 10) + this.xOffset
        pos.y = pos.row + _random(0, 10) + this.yOffset
      }
      return pos
    },
    copyFrameUrl() {
      navigator.clipboard.writeText(
        `https://${window.location.host}/frame/${this.featured.id}`
      );
    },
    setVideoElStyle(lv, mapVP, client) {
      const uid = lv.dataset.vidUid;
      const mapV = mapVP || document.querySelector(`[data-map-uid="${uid}"]`);
      if (lv.style.transform == "scale(-1, 1)") {
        lv.style.transform = "";
      }

      return { lv, mapV };
    },
    initBusEvents() {
      // NOTE: Event handlers for VideoFeed api

      this.videoEventBus.$on("removeVideo", ({ uid }) => {
        this.$store.commit('removeStreamer', uid)
      });

      this.videoEventBus.$on("localMediaView", ({lv, audioTrack, videoTrack}) => {
        if (!lv) return;
        const { vidUid } = lv.dataset;
        if (this.wsPeers.findIndex(p => p.UID == vidUid) > -1 && !this.streamers.find(s => s.uid === vidUid)) {
          if (!this.ringColors.length) this.resetRingColors()
          const streamer = {
            uid: vidUid,
            el: lv,
            color: this.ringColors.splice(0, 1)[0],
            audioTrack,
            videoTrack
          }
          this.$store.commit('addStreamer', streamer)
          // console.log("ADDING STREAMER LOCAL: ", vidUid)
        }
      });

      this.videoEventBus.$on("remoteMediaView", ({lv, connection, videoStream}) => {
        if (!lv) return
        const { vidUid } = lv.dataset;
        if (this.wsPeers.findIndex(p => p.UID == vidUid) > -1 && !this.streamers.find(s => s.uid === vidUid)) {
          if (!this.ringColors.length) this.resetRingColors()
          console.log(videoStream)
          const streamer = {
            uid: vidUid,
            el: lv,
            color: this.ringColors.splice(0, 1)[0],
            videoTrack: videoStream
          }
          this.$store.commit('addStreamer', streamer)
          // console.log("ADDING STREAMER: ", vidUid)
        }
        else connection.close()
      });
      this.videoEventBus.$on("connected", (isConnected) => {
        // if (!isConnected && !window.location.href.includes("monitor")) location.reload();
        this.connecting = false;
      });

      this.videoEventBus.$on("peerLeft", (msg) => {
        // console.log("PEER LEFT: ", msg)
      });

      this.videoEventBus.$on("newWord", ({ uid, transcript}) => {
        if (transcript){
          this.$store.commit("pushWord", {uid: uid, transcript})
          this.sendWord(transcript)
        }
      })

      this.videoFeed = new VideoFeed(this.uid);

      this.videoFeed.join(this.cavempt).then(() => {
        this.videoFeed.startLocalMedia();
      });
    }
  }
};
</script>

<style lang="scss">
@use "sass:math";

.message-container {
  color: white;
  padding: 12px;
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;
}

.connecting-container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.tap-container {
  pointer-events: auto;
}
.tap-to-talk-message {
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  &.active {
    background: white !important;
    color: rgb(43 57 144) !important;
  }
  &.tap-to-talk-desktop {
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    font-size: 24px;
  }
}

.tap-to-talk-mobile {
  display: none;
}

.streaming-service {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 100;
  // background-color: #ffffff;
  margin: 0px auto 0;
  pointer-events: none;
  display: none;
  &.featured-active {
    #video-container,
    .static-container {
      opacity: 0 !important;
    }
  }
  #video-container {
    position: fixed;
    z-index: 100;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;
    // flex-wrap: wrap;
    pointer-events: none;
    &.streamers-4 {
      .streamer {
        width: 50%;
      }
    }
    @media only screen and (max-width: 450px) {
      // height: auto;
      // top: 50%;
      // transform: translateY(-50%);
      // display: block;
      // text-align: center;
      .streamer {
        display: inline-block;
        vertical-align: top;
      }
      &.streamers-4 {
        .streamer {
          width: 170px;
        }
      }
    }
  }
  .static-container {
    .static-streamer {
      height: 300px;
      width: 300px;
      border-radius: 100%;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      will-change: transform;
      // overflow: hidden;
      img {
        height: 300px;
        width: 300px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 100%;
        pointer-events: none;
      }
      @media only screen and (max-width: 450px) {
        height: 150px;
        width: 150px;
        img {
          height: 150px;
          width: 150px;
          padding: 15px;
        }
      }
      .time {
        color: #000;
        font-size: 14px;
        white-space: nowrap;
        width: 300px;
        height: 300px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        @media only screen and (max-width: 450px) {
          height: 150;
          width: 150;
          font-size: 12px;
        }
      }
    }
  }
  .featured-container {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-copy {
      position: fixed;
      bottom: 4px;
      right: 3px;
      stroke: #fff;
      stroke-width: 2px;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 100%;
      padding: 40px;
      box-sizing: border-box;
    }
    .time {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, calc(-50% - 8px));
      transform-origin: unset;
      animation-name: unset;
      font-size: 24px;
    }
  }
}
</style>
