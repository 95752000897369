<template>
  <div v-if="showFrame" :class="['frame', {'playing': isPlaying, 'ready': ready}]">
    <!-- <video v-if="!isMobile" :poster="posterUrl" preload="metadata" muted loop playsinline ref="video" :src="`${videoUrl}#t=0.1`"></video> -->
    <div class="sizer" :style="{paddingBottom: `${aspectRatio}%`}">
      <img :src="posterUrl" @error="hideFrame" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showFrame: true,
      isPlaying: false,
      vid: false,
      ready: false,
      urlBase: "https://ce-000-streams-transcoded.s3.ap-northeast-1.amazonaws.com/",
      posterUrlBase: "https://d5i40nk2yn9a8.cloudfront.net/",
      isMobile: window.innerWidth < 450
    }
  },
  computed: {
    aspectRatio() {
      return Math.round(((this.height / this.width) * 100))
    },
    videoUrl() {
      return `${this.urlBase}${this.file}/${this.file}.mp4`
    },
    posterUrl() {
      return `${this.posterUrlBase}${this.file}/${this.file}-00001.png`
    }
  },
  methods: {
    hideFrame() {
      this.showFrame = false
    },
    togglePlay() {
      if (this.isMobile) return
      if (!this.isPlaying) this.$refs.video.play()
      else this.$refs.video.pause()
      this.isPlaying = !this.isPlaying
    },
    play() {
      if (this.isMobile) return
      this.$refs.video.play()
    },
    pause() {
      if (this.isMobile) return
      this.$refs.video.pause()
    },

  }
}

</script>

<style lang="scss">

.frame {
  position: relative;
  width: calc(100% - 8px);
  margin: 4px 4px 8px 4px;
  box-sizing: border-box; 
  cursor: pointer;
  // border: 1px dotted #000;
  .sizer {
    width: 100%;
    height: 0;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

</style>