<template>
  <div class="app">
    <!-- <new-header v-if="!isNewHome" /> -->
    <div id="main" class="mode-grid">
      <router-view :peers="wsPeers" />
    </div>
  </div>
</template>

<script>
//Components
import NewHeader from "./components/NewHeader.vue"

export default {
  components: {
    NewHeader
  },
  data() {
    return {
      wsPeers: []
    };
  },
  methods: {
    setPeers(peers) {
      this.wsPeers = peers;
    }
  },
};
</script>
<style lang="scss">
.app {
  // margin-top: 50px;
}

.detail-open {
  .archive-link {
    right: 42px;
  }
}

.mailinglist {
  font-size: 25px;
  line-height: 1;
  margin-left: 12px;
  vertical-align: bottom;
  cursor: pointer;
  opacity: 0.75;
  &.active,
  &:hover {
    opacity: 1;
  }
}

@media only screen and (max-width: 450px) {
  header {
    padding: 0 15px;
    .teaser {
      display: none;
    }
    .header__modes {
      .map {
        display: none;
      }
      a:last-child {
        margin-left: 0;
      }
      a:first-child {
        margin-right: 15px;
      }
      .archive-link {
        right: 15px;
      }
    }
  }
}

// Fade Transition
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
</style>
