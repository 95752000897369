import Vue from "vue";
import Vuex from 'vuex'
import store from "./store.js"
import App from "./App.vue";
import "./styles/base.scss";
import VueRouter from "vue-router";
import Home from "./pages/Home.vue";

const routes = [
  { path: "", component: Home, name: "home" }
];

const router = new VueRouter({ routes, mode: "history" });

Vue.use(VueRouter);
Vue.use(Vuex)

window.w = new Vue({
  el: "#app",
  router: router,
  store,
  render: h => h(App)
});