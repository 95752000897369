<template>
    <div id="SC-Player" :class="classes" @click="toggleOpen">
      <img src="~/assets/tape.png" alt="">
      <div class="close">
        <div class="line line-1"></div>
        <div class="line line-2"></div>
      </div>
        <div class="images">
            <div
                v-for="(s, i) in sounds"
                ref="tape"
                :class="tapeIds[i]"
                @click.stop="setPlaying(i)"
            >
                <img
                    v-if="!s.exactPath"
                    :src="`${imageHost}/thumbs/${stripSpaces(s.s3path)}/500x500.jpg`"
                />
                <img v-else :src="s.exactPath" />
            </div>
        </div>
        <div ref="players" class="players">
           <audio v-for="(s, i) in sounds" :ref="`player-${i}`" :src="`${imageHost}/tapes/${tapeIds[i]}.aac`" preload="none"></audio>
        </div>
    </div>
</template>

<script>
import tapeData from "../assets/data/tapeData.json"

export default {
    data() {
        return {
            isOpen: false,
            tapeData: tapeData,
            imageHost: "https://d2bsictc2yhqs0.cloudfront.net"
        }
    },
    computed: {
        classes() {
            return ["listen-controls", { open: this.isOpen }]
        },
        sounds() {
            return Object.values(this.tapeData)
        },
        tapeIds() {
            return Object.keys(this.tapeData)
        },
    },
    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen
        },
        setPlaying(soundIdx) {
            let activeTape = ""
            for (let i = 0; i < this.sounds.length; i++) {
              if (soundIdx == i && !this.sounds[i].playing) {
                this.$refs[`player-${i}`][0].play().catch(e => {
                  console.log("ERROR")
                  console.log(e)
                })
                this.sounds[i].playing = true
                this.$refs.tape[i].classList.add("playing")
                activeTape = this.sounds[i].title
                this.$store.commit("setAudioCtx", this.$refs[`player-${i}`][0])
                this.$store.commit("setAudioId", this.tapeIds[i])
              }
              else {
                this.$refs[`player-${i}`][0].pause()
                this.sounds[i].playing = false
                this.$refs.tape[i].classList.remove("playing")
              }
            }
            this.$emit('playing', activeTape)
        },
        stripSpaces(str) {
            return str.replace(/ /g, "_")
        },
    },
}
</script>

<style lang="scss">

.listen-controls {
    position: fixed;
    top: 0px;
    right: 10px;
    height: 50px;
    // width: 35px;
    border-radius: 5px;
    z-index: 500;
    // background-color: #000;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        & > img {
            opacity: 1;
        }
    }
    & > img {
        height: 50px;
        width: auto;
        padding: 5px;
        box-sizing: border-box;
        opacity: 1;
    }
    .close {
      height: 30px;
      width: 30px;
      margin: 10px 22px;
      position: relative;
      display: none;
      .line {
        height: 4px;
        width: 100%;
        background-color: #000;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) rotate(45deg);
        &-2 {
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
    &.open {
        & > img {
            display: none;
        }
        .close {
          display: block;
        }
        .images {
            display: block;
        }
    }
    .players {
        // Players need to have dimentions to prevent js errors
        height: 100px;
        width: 100px;
        position: fixed;
        left: 0;
        top: 0;
        transform: translate(calc(-100% - 10px), calc(-100% - 10px));
        iframe {
            height: 100%;
            width: 100%;
        }
    }
    .images {
        position: fixed;
        top: 55px;
        right: 15px;
        width: calc(100% - 30px);
        height: calc(100% - 50px - 25px);
        background-color: white;
        overflow-y: auto;
        display: none;
        border: 1px solid #000;
        & > div {
            height: auto;
            width: 25%;
            display: inline-block;
            vertical-align: top;
            position: relative;
            img {
                width: 100%;
                // height: 100%;
                object-fit: contain;
                padding: 20px 10px;
                box-sizing: border-box;
            }
            &::after {
                content: " ";
                display: block;
                width: 100px;
                height: 100px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url("../assets/play.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                opacity: 0;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
            &.playing {
                &::after {
                    background-image: url("../assets/pause.svg");
                    opacity: 1;
                }
            }
        }
    }
    // Breakpoints
    @media only screen and (max-width: 450px) {
        // top: 14px;
        right: 5px;
        & > img {
          // filter: invert(1);
        }
        .images {
          right: 5px;
          width: calc(100% - 10px);
          height: calc(100% - 50px - 10px);
          & > div {
            width: 50%;
          }
          & > div::after {
            width: 60px;
            height: 60px;
          }
        }
    }
}
</style>
