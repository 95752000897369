<template>
  <div class="streamer-frame">
    <div class="canvas-sizer" ref="canvasWrapper"></div>
  </div>
</template>

<script>
import { betterDitherShader } from "../lib/ShadersNew.js" 
import { Flock, Boid } from "../lib/Flocking.js"
import { RecordCanvasMixin } from "../lib/RecordCanvasMixin.js"

let p5Runtime

export default {
  mixins: [RecordCanvasMixin],
  props: {
    streamer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      aspectRatio: 19.5 / 9,
      canvasRecorded: false,
      videoStreamDimentions: false
    }
  },
  computed: {
    isLocalStreamer() {
      return this.streamer.uid == this.$store.state.userData.UID
    }
  },
  mounted() {
    this.setVideoStreamDimentions()
    this.start()
  },
  beforeDestroy() {
    if (p5Runtime) p5Runtime.remove()
    if (this.recordingTimeout) clearTimeout(this.recordingTimeout)
    this.cancelRecording()
  },
  methods: {
    setVideoStreamDimentions() {
      if (this.streamer.videoTrack){
        const videoStreamSettings = this.streamer.videoTrack.getSettings()
        const {height, width } = videoStreamSettings
        if (height && width) {
          this.videoStreamDimentions = {height, width}
        }
      }
    },
    start() {
      const config = p => {
        const width = 400
        const height = width * this.aspectRatio
        let videoBuffer = p.createGraphics(width, height)
        let wordBuffer = p.createGraphics(width, height)
        let glitch;
        let dither;
        let flock;
        let hasWords = false
        const startTime = Date.now()
        p.setup = () => {
          p.pixelDensity(1);
          p.createCanvas(width, height, p.WEBGL2)
          p.fill("#fff")
          dither = p.createFilterShader(betterDitherShader)
          p5Runtime = p
        }
        p.draw = () => {
          if (!this.videoStreamDimentions) this.setVideoStreamDimentions()
          if (this.videoStreamDimentions) {
            const videoAspectRatio = this.videoStreamDimentions.height /this.videoStreamDimentions.width
            let sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight
            if (videoAspectRatio <= this.aspectRatio) {
              //Video is wide, lock to height of video and crop sides
              const targetWidth = 1 / (this.aspectRatio * (1 / this.videoStreamDimentions.height))
              sx = (this.videoStreamDimentions.width - targetWidth) / 2
              sy = 0
              sWidth = targetWidth
              sHeight = this.videoStreamDimentions.height
              dx = 0
              dy = 0
              dWidth = p.width
              dHeight = p.height
            }
            else {
              const targetheight = this.aspectRatio * this.videoStreamDimentions.width
              sx = 0
              sy = (this.videoStreamDimentions.height - targetheight) / 2
              sWidth = this.videoStreamDimentions.width
              sHeight = targetheight
              dx = 0
              dy = 0
              dWidth = p.width
              dHeight = p.height
            }
            // console.log(`sx: ${sx}, dWidth: ${dWidth}, dHeight: ${dHeight}, sWidth: ${sWidth}`)
            videoBuffer.drawingContext.drawImage(this.streamer.el.firstChild, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
          }
          else {
            videoBuffer.drawingContext.drawImage(this.streamer.el.firstChild, 0, 0)
          }

          // Draw Words
          wordBuffer.drawingContext.clearRect(0, 0, wordBuffer.canvas.width, wordBuffer.canvas.height);
          const words = this.$store.state.words[this.streamer.uid] || []
          if (words.length) {
            this.$store.commit("clearStreamerWords", this.streamer.uid)
            if (!flock) flock = new Flock(wordBuffer)
            for (const i in words) {
              let b = new Boid(Math.random() * width, Math.random() * height, wordBuffer, words[i], this.streamer.color);
              flock.addBoid(b);
            }
            hasWords = true
          }
          if (hasWords) flock.run()

          p.image(videoBuffer, 0, 0)
          dither.setUniform('time', (Date.now() - startTime) / 1000.0);
          p.filter(dither)
          p.image(wordBuffer, 0, 0)

          // If streamer is on canvas and a word is on canvas record it
          if (this.isLocalStreamer && !this.canvasRecorded && hasWords) {
            let captureTracks = [this.streamer.audioTrack]
            captureTracks = captureTracks.concat(p.canvas.captureStream(30).getTracks())
            const captureStream = new MediaStream(captureTracks)
            this.recordingTimeout = setTimeout(() => this.recordCanvas(captureStream), 500)
            this.canvasRecorded = true
          }
        }
      }
      new p5(config, this.$refs.canvasWrapper)
    }
  }
}

</script>

<style lang="scss">

.streamer-frame {
  position: relative;
  width: 100%;
  padding: 4px;
  .canvas-sizer {
    padding-bottom: 216.66%;
    width: 100%;
    position: relative;
  }
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }
  @media only screen and (max-width: 600px) {
    padding: 2px;
  }
}

</style>