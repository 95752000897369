import api from "./api.js"
import AWS from "aws-sdk"

export const RecordCanvasMixin = {
  data() {
    return {
      recorder: false,
      cancelRecorder: false,
      recordingTimeout: false,
      s3: false,
      dbUpdated: false,
      s3Config: {
        bucketName: "ce-000-test",
        region: "ap-northeast-1",
        identityPool: "ap-northeast-1:a3693cf5-e524-4ea6-8fa9-d62e9e7ad44a"
      }
    }
  },
  computed: {
    fileKey() {
      return this.$store.state.userData.FileKey
    }
  },
  mounted() {
    AWS.config.update({
      region: this.s3Config.region,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.s3Config.identityPool
      })
    });
    
  },
  methods: {
    cancelRecording() {
      this.cancelRecorder = true
      if (this.recorder) {
        this.recorder.stop()
        clearTimeout(this.recordingTimeout)
      }
    },
    recordCanvas(canvasStream) {
      this.cancelRecorder = false 
      let chunkCounter = 0
      let mimeType = "video/webm"
      if (!MediaRecorder.isTypeSupported(mimeType)) mimeType = 'video/mp4'
      const ext = mimeType == 'video/webm' ? 'webm' : 'mp4'
      this.recorder = new MediaRecorder(canvasStream, {mimeType})
      this.recorder.ondataavailable = (e) => {
        if (!this.cancelRecorder){
          chunkCounter++
          const upload = new AWS.S3.ManagedUpload({
            params: {
              Bucket: this.s3Config.bucketName,
              Key: `${this.fileKey}/${String(chunkCounter).padStart(10, '0')}.${ext}`,
              Body: e.data
            }
          });
          upload.promise().then(data => {
            console.log("UPLOAD DONE")
            if (!this.dbUpdated) {
              api.uploadFrame(this.fileKey, this.$store.state.userData, 400, 400 * (19.5 / 9))
              this.dbUpdated = true
            }
          },
          err => {
            console.log(err)
          })
        }
      }
      this.recorder.onstop = e => {
        this.recorder = false
      }
      console.log("START")
      this.recorder.start(100)
      this.recordingTimeout = setTimeout(() => {
        console.log("STOP")
        this.recorder.stop()
      }, 600000)
    },
  }
}