import p5 from "p5"

export function Flock(p) {
  // An array for all the boids
  this.boids = []; // Initialize the array
  this.p = p
}

Flock.prototype.run = function() {
  // const baseFontSize = window.innerWidth < 450 ? 72 : 120
  const baseFontSize = window.innerWidth < 450 ? 72 : 80
  for (let i = 0; i < this.boids.length; i++) {
    const percentCompleted = (10 - i) / 10
    this.boids[i].fontSize = percentCompleted * baseFontSize
    this.boids[i].opacity = percentCompleted
    this.boids[i].percentCompleted = percentCompleted
    if (percentCompleted <= 0) {
      this.boids[i].fontSize = baseFontSize * .1
      if (this.boids[i].fontSize < 8) this.boids[i].fontSize = 8
      this.boids[i].opacity = 0.1
    }
  }
  this.boids = this.boids.filter(b => b.percentCompleted > 0)
  for (let i = 0; i < this.boids.length; i++) this.boids[i].run(this.boids)
}

Flock.prototype.addBoid = function(b) {
  this.boids.unshift(b);
  if (this.boids.length > 20) this.boids.splice(19, this.boids.length - 20)
}

export function Boid(x, y, p, word, color) {
  this.startTime = Date.now()
  this.percentCompleted = 0
  this.duration = 30000
  this.color = color
  this.fontSize = window.innerWidth < 450 ? 64 : 100
  this.p = p
  this.word = word
  this.acceleration = this.p.createVector(0, 0);
  let xVector = this.p.random(1.5, 2)
  if (this.p.random() > .5) xVector = xVector * -1
  let yVector = this.p.random(-1, 1)
  if (this.p.random() > .5) yVector = yVector * -1
  this.velocity = this.p.createVector(xVector, yVector);
  this.position = this.p.createVector(x, y);
  this.r = 3.0;
  this.maxspeed = 3;    // Maximum speed
  this.maxforce = 0.05; // Maximum steering force
}

Boid.prototype.run = function() {
  this.update();
  this.borders();
  this.render();
}

Boid.prototype.applyForce = function(force) {
  // We could add mass here if we want A = F / M
  this.acceleration.add(force);
}

// We accumulate a new acceleration each time based on three rules
Boid.prototype.flock = function(boids) {
  let sep = this.separate(boids);   // Separation
  let ali = this.align(boids);      // Alignment
  let coh = this.cohesion(boids);   // Cohesion
  // Arbitrarily weight these forces
  sep.mult(1.5);
  ali.mult(1.0);
  coh.mult(1.0);
  // Add the force vectors to acceleration
  this.applyForce(sep);
  this.applyForce(ali);
  this.applyForce(coh);
}

// Method to update location
Boid.prototype.update = function() {
  // Update velocity
  this.velocity.add(this.acceleration);
  // Limit speed
  this.velocity.limit(this.maxspeed);
  this.position.add(this.velocity);
  // Reset accelertion to 0 each cycle
  this.acceleration.mult(0);
}

// A method that calculates and applies a steering force towards a target
// STEER = DESIRED MINUS VELOCITY
Boid.prototype.seek = function(target) {
  let desired = p5.Vector.sub(target,this.position);  // A vector pointing from the location to the target
  // Normalize desired and scale to maximum speed
  desired.normalize();
  desired.mult(this.maxspeed);
  // Steering = Desired minus Velocity
  let steer = p5.Vector.sub(desired,this.velocity);
  steer.limit(this.maxforce);  // Limit to maximum steering force
  return steer;
}

Boid.prototype.render = function() {
  // Draw a triangle rotated in the direction of velocity
  this.p.push()
  const color = this.p.color(this.color)
  color.setAlpha(this.opacity * 255)
  this.p.noStroke()
  this.p.fill(color);
  this.p.translate(this.position.x, this.position.y);
  // this.p.textStyle(this.p.BOLD);
  this.p.textFont('Times New Roman');
  this.p.textSize(this.fontSize)
  this.p.text(this.word, 0, 0)
  this.p.pop();
}

// Wraparound
Boid.prototype.borders = function() {
  if (this.position.x < -this.r)  this.position.x = this.p.width + this.r;
  if (this.position.y < -this.r)  this.position.y = this.p.height + this.r;
  if (this.position.x > this.p.width + this.r) this.position.x = -this.r;
  if (this.position.y > this.p.height + this.r) this.position.y = -this.r;
}

// Separation
// Method checks for nearby boids and steers away
Boid.prototype.separate = function(boids) {
  let desiredseparation = 25.0;
  let steer = this.p.createVector(0, 0);
  let count = 0;
  // For every boid in the system, check if it's too close
  for (let i = 0; i < boids.length; i++) {
    let d = p5.Vector.dist(this.position,boids[i].position);
    // If the distance is greater than 0 and less than an arbitrary amount (0 when you are yourself)
    if ((d > 0) && (d < desiredseparation)) {
      // Calculate vector pointing away from neighbor
      let diff = p5.Vector.sub(this.position, boids[i].position);
      diff.normalize();
      diff.div(d);        // Weight by distance
      steer.add(diff);
      count++;            // Keep track of how many
    }
  }
  // Average -- divide by how many
  if (count > 0) {
    steer.div(count);
  }

  // As long as the vector is greater than 0
  if (steer.mag() > 0) {
    // Implement Reynolds: Steering = Desired - Velocity
    steer.normalize();
    steer.mult(this.maxspeed);
    steer.sub(this.velocity);
    steer.limit(this.maxforce);
  }
  return steer;
}

// Alignment
// For every nearby boid in the system, calculate the average velocity
Boid.prototype.align = function(boids) {
  let neighbordist = 50;
  let sum = this.p.createVector(0,0);
  let count = 0;
  for (let i = 0; i < boids.length; i++) {
    let d = p5.Vector.dist(this.position,boids[i].position);
    if ((d > 0) && (d < neighbordist)) {
      sum.add(boids[i].velocity);
      count++;
    }
  }
  if (count > 0) {
    sum.div(count);
    sum.normalize();
    sum.mult(this.maxspeed);
    let steer = p5.Vector.sub(sum, this.velocity);
    steer.limit(this.maxforce);
    return steer;
  } else {
    return this.p.createVector(0, 0);
  }
}

// Cohesion
// For the average location (i.e. center) of all nearby boids, calculate steering vector towards that location
Boid.prototype.cohesion = function(boids) {
  let neighbordist = 50;
  let sum = this.p.createVector(0, 0);   // Start with empty vector to accumulate all locations
  let count = 0;
  for (let i = 0; i < boids.length; i++) {
    let d = p5.Vector.dist(this.position,boids[i].position);
    if ((d > 0) && (d < neighbordist)) {
      sum.add(boids[i].position); // Add location
      count++;
    }
  }
  if (count > 0) {
    sum.div(count);
    return this.seek(sum);  // Steer towards the location
  } else {
    return this.p.createVector(0, 0);
  }
}
