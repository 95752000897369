/// <reference path="./fm.liveswitch.d.ts" />

// eslint-disable no-unused-vars
// eslint-disable no-console
import Vue from "vue";
import api from "./api";

import * as Comlink from "comlink"

const deadFrameWorker = Comlink.wrap(new Worker("DeadFrameWorker.js"))

const lsGatewayURL = "https://cloud.liveswitch.io/";

export const bus = new Vue();

const iceServers = [
  new fm.liveswitch.IceServer("stun:turn.frozenmountain.com:3478"),
  new fm.liveswitch.IceServer(
    "turn:turn.frozenmountain.com:80",
    "test",
    "pa55w0rd!"
  ),
  new fm.liveswitch.IceServer(
    "turns:turn.frozenmountain.com:443",
    "test",
    "pa55w0rd!"
  )
];

/*
    1. registerToken() get a auth token from server server
    2. register the client using the token
    3. onClientRegistered =>  startLocalMedia
    4. openSfuUpstreamConnection
  */
export const promisObj = () => {
  let resolve = v => {
    v;
  };
  let reject = err => {
    err;
  };
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });

  return {
    promise,
    then: f => promise.then(f),
    catch: f => promise.catch(f),
    resolve,
    reject
  };
};

class VideoFeed {
  constructor(uid) {
    // add registeredPromise to ensure we can wait for this
    this.registeredPromise = promisObj();

    this.userId = fm.liveswitch.Guid.newGuid()
      .toString()
      .replace(/-/g, "");
    this.deviceId = fm.liveswitch.Guid.newGuid()
      .toString()
      .replace(/-/g, "");

    this.clientId = "64c140a782364d5b86861919865a094f";

    this.userName = `${uid}`;

    this.setupLogging();

    fm.liveswitch.Util.observe(window, "beforeundload", () => {
      this.leave();
    });

    this.media = {};
    this.micControls = false; 
    this.audioAnalyzer = false;
    this.audioAnalyzers = {};
    this.deadFrameCount = 0;
    this.deadFrameInterval = false;
  }

  getLang(countryCode) {
    const availableLangs = ["zh", "zh-TW", "da", "nl", "en-AU", "en-GB", "hi", "en-NZ", "en-US", "fr", "de", "id", "it", "ja", "ko", "no", "pl", "pt", "ru", "sv", "tr", "uk", "es"]
    if (availableLangs.includes(window.navigator.language)) {
      const lang = window.navigator.language
      let model = "base"
      if (["en", "en-AU", "en-GB", "en-IN", "en-NZ", "en-US", "es"].includes(lang)) model = 'nova'
      return {
        lang,
        model
      }
    }
    else if (availableLangs.includes(window.navigator.language.split("-")[0])) return window.navigator.language.split("-")[0]
    let out = {
      lang: "en-US",
      model: "nova"
    }
    switch(countryCode) {
      case "CN":
        out = {
          lang: "zh",
          model: "base" 
        }
        break
      case "TW":
        out = {
          lang: "zh-TW",
          model: "base" 
        }
        break
      case "DK":
        out = {
          lang: "da",
          model: "base"
        }
        break
      case "NL":
        out = {
          lang: "nl",
          model: "base"
        }
        break
      case "AU":
        out = {
          lang: "en-AU",
          model: "nova"
        }
        break
      case "GB":
        out = {
          lang: "en-GB",
          model: "nova"
        }
        break
      case "IN":
        out = {
          lang: "hi",
          model: "base"
        }
        break
      case "NZ":
        out = {
          lang: "en-NZ",
          model: "nova"
        }
        break
      case "US":
        out = {
          lang: "en-US",
          model: "nova"
        }
        break
      case "FR":
        out = {
          lang: "fr",
          model: "base"
        }
        break
      case "DE":
        out = {
          lang: "de",
          model: "base"
        }
        break
      case "ID":
        out = {
          lang: "id",
          model: "base"
        }
        break
      case "IT":
        out = {
          lang: "it",
          model: "base"
        }
        break
      case "JP":
        out = {
          lang: "ja",
          model: "base"
        }
        break
      case "KR":
        out = {
          lang: "ko",
          model: "base"
        }
        break
      case "NO":
        out = {
          lang: "no",
          model: "base"
        }
        break
      case "PL":
        out = {
          lang: "pl",
          model: "base"
        }
        break
      case "BR":
      case "PT":
        out = {
          lang: "pt",
          model: "base"
        }
        break
      case "RU":
        out = {
          lang: "ru",
          model: "base"
        }
        break
      case "SE":
        out = {
          lang: "sv",
          model: "base"
        }
        break
      case "TR":
        out = {
          lang: "tr",
          model: "base"
        }
        break
      case "UA":
        out = {
          lang: "uk",
          model: "base"
        }
        break
      case "MX":
      case "AR":
      case "CL":
      case "CO":
      case "CR":
      case "CU":
      case "DO":
      case "EC":
        out = {
          lang: "es",
          model: "nova"
        }
        break
    }
    return out
  }

  setupTranscription(mediaRecorder, uid) {

    const langConig = this.getLang(this.me.Location.CountryCode)

    console.log("LANG :", langConig)

    this.transcriptionSocket = new WebSocket(`wss://api.deepgram.com/v1/listen?encoding=opus&smart_format=false&punctuate=false&paragraphs=false&utterances=true&language=${langConig.lang}&model=base`, ['token', "7f451d4cc0dcead236bd315353797c46203c2943"])

    this.transcriptionSocket.onclose = e => {
      mediaRecorder.stop()
      console.log("CLOSE")
    }

    this.transcriptionSocket.onerror = e => {
      console.log("TRANSCRIPTION ERROR")
      console.log(e)
    }

    this.transcriptionSocket.onopen = () => {
      mediaRecorder.addEventListener('dataavailable', e => {
        if (e.data.size && this.transcriptionSocket.readyState == 1) this.transcriptionSocket.send(e.data)
      })
      mediaRecorder.start(250)
    }

    this.transcriptionSocket.onmessage = (message) => {
      const recieved = JSON.parse(message.data)
      if (recieved.channel && recieved.channel.alternatives) {
        const transcript = recieved.channel.alternatives[0].transcript
        bus.$emit('newWord', transcript)
        bus.$emit('newWord', {transcript, uid})
      }
    }

    // Close after 10mins to prevent over billing 
    setTimeout(() => {
      console.log("ending transcription...")
      this.transcriptionSocket.close()
    }, 60000)

  }

  setupLogging() {
    // Log to console and the DOM.
    // fm.liveswitch.Log.registerProvider(
    //   new fm.liveswitch.ConsoleLogProvider(fm.liveswitch.LogLevel.Debug)
    // );
  }

  async registerToken() {
    const { deviceId, userId, clientId } = this;

    const res = await api.registerToken({ deviceId, userId, clientId });
    const { token, channelId, applicationId } = res.data;
    this.token = token;
    this.channelId = channelId;
    this.applicationId = applicationId;
    return token;
  }

  async join(meCe) {
    console.log("Joining....")
    this.me = meCe;
    const token = await this.registerToken();
    this.client = new fm.liveswitch.Client(
      lsGatewayURL,
      this.applicationId,
      this.userId,
      this.deviceId
    );
    this.client.setUserAlias(this.userName);
    this.client.setTag(JSON.stringify(meCe));

    console.log("connected to video client...")

    const channels = await this.client.register(token).fail(ex => {
      console.log(ex);
      console.log(JSON.stringify(ex + " " + "token: " + token))
    });
    console.log("connected to channel...")
    this.registeredPromise.resolve(channels[0]);
    this.onClientRegistered(channels);
    return;
  }

  leave() {
    var promise = new fm.liveswitch.Promise();
    if (this.transcriptionSocket) this.transcriptionSocket.close()  
    this.client
      .unregister()
      .then(function() {
        fm.liveswitch.Log.info("Unregistered");
      })
      .fail(function() {
        fm.liveswitch.Log.debug("Failed to unregister client.");
      });
    try {
      let { localMedia, layoutManager } = this;
      if (localMedia == null) {
        promise.resolve(null);
        return promise;
      }
      localMedia.stop().then(
        () => {
          if (layoutManager != null) {
            layoutManager.removeRemoteViews();
            layoutManager.unsetLocalView();
            layoutManager = null;
          }
          if (localMedia != null) {
            localMedia = null;
          }
          promise.resolve(null);
          clearInterval(this.deadFrameInterval);
          bus.$emit("removeVideo", { uid: this.userName });
        },
        ex => {
          promise.reject(ex);
        }
      );
    } catch (ex) {
      promise.reject(ex);
    }
    return promise;
  }

  startLocalMedia() {
    this.registeredPromise.then(channel => {
      this.channel = channel;
      this._startLocalMedia();
    });
  }

  _startLocalMedia() {
    let promise = new fm.liveswitch.Promise();
    try {
      if (this.localMedia != null) {
        throw new Error("Local media has already been started.");
      }

      // console.log("STARTING LOCAL MEDIA...")

      const audioSettings = {
        echoCancellation: true
      };

      let videoSettings = {
        frameRate: 30
      };

      this.localMedia = new fm.liveswitch.LocalMedia(
        audioSettings,
        videoSettings
      );

      this.localMedia
        .start()
        .then(() => {
          const lv = this.localMedia.getView();
          lv.dataset.vidUid = this.userName;

          // console.log(this.localMedia.getAudioTrack()._getInternal())

          const audioTrack = this.localMedia.getAudioTrack()._getInternal()._mediaStreamTrack
          const videoTrack = this.localMedia.getVideoTrack()._getInternal()._mediaStreamTrack

          this.media[lv.dataset.vidUid] = this.localMedia;
          this.audioAnalyzers[lv.dataset.vidUid] = false;

          let mimeType = ""
          if (MediaRecorder.isTypeSupported('audio/webm')) mimeType = 'audio/webm'
          else if (MediaRecorder.isTypeSupported('audio/mp4')) mimeType = 'audio/mp4;codecs:opus'

          const audioMediaStream = new MediaStream()
          audioMediaStream.addTrack(audioTrack)
          const mediaRecorder = new MediaRecorder(audioMediaStream, {
            mimeType: mimeType,
          });

          if (!window.location.search.includes('quiet')) this.setupTranscription(mediaRecorder, lv.dataset.vidUid)

          bus.$emit("localMediaView", {lv, audioTrack, videoTrack});

          this.openSfuUpstreamConnection("up-").then(() => {
            bus.$emit("connected", true);

            this.deadFrameInterval = setInterval(() => {
              this.localMedia.grabVideoFrame().then(buffer => {
                deadFrameWorker.checkFrame(buffer.__dataBuffers[0]._innerData).then(isDead => {
                  if (isDead) this.leave();
                })
              });
            }, 5000);
          });
        })
        .fail((err) => {
          console.log(err)
          bus.$emit("connected", false);
        });
      return promise.resolve(null);
    } catch (ex) {
      bus.$emit("connected", false);
      promise.reject(ex);
    }
    return promise;
  }

  initAudioAnalyzer(audioStream) {
    let ctxConstructor = window.AudioContext || window.webkitAudioContext;
    const ctx = new ctxConstructor();
    const sourceNode = ctx.createMediaStreamSource(audioStream);
    const destination = ctx.createMediaStreamDestination();
    const analyzerNode = ctx.createAnalyser();
    const gainNode = ctx.createGain();
    gainNode.gain.value = 1;
    analyzerNode.fftSize = 1024;
    sourceNode
      .connect(analyzerNode)
      .connect(gainNode)
      .connect(destination);
    return analyzerNode;
  }

  onClientRegistered(channels) {
    const channel = (this.channel = channels[0]);

    //
    channel.addOnRemoteUpstreamConnectionOpen(remoteConnectionInfo => {
      new fm.liveswitch.RemoteMedia();

      this.openSfuDownstreamConnection(remoteConnectionInfo);
    });

    // Monitor the channel remote client changes.
    this.channel.addOnRemoteClientJoin(remoteClientInfo => {
      fm.liveswitch.Log.info(
        `Remote client joined the channel (client ID: ${remoteClientInfo.getId()}, device ID: ${remoteClientInfo.getDeviceId()} user ID: ${remoteClientInfo.getUserId()} +, tag: ${remoteClientInfo.getTag()}).`
      );

      let n =
        remoteClientInfo.getUserAlias() != null
          ? remoteClientInfo.getUserAlias()
          : remoteClientInfo.getUserId();

      bus.$emit("peerJoined", n);
    });

    this.channel.addOnRemoteClientLeave(remoteClientInfo => {
      let n =
        remoteClientInfo.getUserAlias() != null
          ? remoteClientInfo.getUserAlias()
          : remoteClientInfo.getUserId();
      // peerLeft(n);
      bus.$emit("removeVideo", { uid: n });

      fm.liveswitch.Log.info(
        `Remote client left the channel (client ID: ${remoteClientInfo.getId()} , device ID: ${remoteClientInfo.getDeviceId()}, user ID: ${remoteClientInfo.getUserId()}, tag: ${remoteClientInfo.getTag()} ).`
      );
    });
    this.channel.addOnRemoteUpstreamConnectionClose(remoteConnectionInfo => {
      let n =
        remoteConnectionInfo.getUserAlias() != null
          ? remoteConnectionInfo.getUserAlias()
          : remoteConnectionInfo.getUserId();

      bus.$emit("removeVideo", { uid: n });
    });

    // Open a downstream SFU connection for each remote upstream connection.
    for (let remoteConnectionInfo of this.channel.getRemoteUpstreamConnectionInfos()) {
      this.openSfuDownstreamConnection(remoteConnectionInfo);
    }
  }

  openSfuUpstreamConnection(/* tag */) {
    const { channel, localMedia } = this;

    var audioStream = new fm.liveswitch.AudioStream(localMedia, null);
    let videoStream = new fm.liveswitch.VideoStream(localMedia, null);
    let connection = channel.createSfuUpstreamConnection(
      audioStream,
      videoStream
    );

    // connection.setIceServers(iceServers);
    const conn = connection.open();

    conn
      .then(() => {
        console.log("upstream connection established");
      })
      .fail((err) => {
        console.log(err)
        console.log("an error occurred");
      });

    return conn;
  }

  openSfuDownstreamConnection(remoteConnectionInfo /*, tag */) {
    let remoteMedia = new fm.liveswitch.RemoteMedia();
    const lv = remoteMedia.getView();
    let uid =
      remoteConnectionInfo.getUserAlias() != null
        ? remoteConnectionInfo.getUserAlias()
        : remoteConnectionInfo.getUserId();

    lv.dataset.vidUid = uid;
    this.media[uid] = remoteMedia;

    let audioStream = new fm.liveswitch.AudioStream(remoteMedia);
    let videoStream = new fm.liveswitch.VideoStream(remoteMedia);
    let connection = this.channel.createSfuDownstreamConnection(
      remoteConnectionInfo,
      audioStream,
      videoStream
    );

    // connection.setIceServers(iceServers);
    connection
      .open()
      .then(() => {
        const videoStreamTrack = videoStream.getRemoteMedia()._getInternal()._videoMediaStream.getVideoTracks()[0]      
        console.log(videoStreamTrack)  
        bus.$emit("remoteMediaView", {lv, connection, videoStream: videoStreamTrack});
      })
      .fail((err) => {
        console.log(err)
        console.log("an error occurred");
      });
    
    return connection;
  }

  prepareConnection(connection) {
    let audioStream = connection.getAudioStream();
    if (audioStream) {
      audioStream.setOpusDisabled(false);
      audioStream.setG722Disabled(false);
      audioStream.setPcmuDisabled(false);
      audioStream.setPcmaDisabled(false);
    }

    let videoStream = connection.getVideoStream();
    if (videoStream) {
      videoStream.setVp8Disabled(true);
      videoStream.setVp9Disabled(true);
      videoStream.setH264Disabled(false);
    }
  }
}

export default VideoFeed;