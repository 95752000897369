import _random from "lodash/random";

export const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));

export const getVideoFilters = () => {
  //Greyscale 0 -> 100
  //Invert 0 -> 100 only if > 70
  //Contrast 100 -> 300
  const greyscale = _random(0.0, 1.0, true);
  let invert = _random(0.0, 1.0, true);
  if (invert < 0.7) invert = 0;
  const contrast = _random(1.0, 3.0, true);
  return `grayscale(${greyscale}) invert(${invert}) contrast(${contrast})`;
};

// Init the starting message....
// Just spaces and 0s - but primarily 0s.
export const initialString = (len = 24) => {
  let tmp = [];
  for (let i = 0; i < len; i++) {
    if (getRandomInt(5) == 0) {
      tmp.push(" ");
    } else {
      tmp.push("0");
    }
  }
  return tmp.join("");
};

export const wssProto = () =>
  location.protocol === "https" || location.protocol === "https:"
    ? "wss"
    : "ws";

export const now = () => {
  let n = new Date();
  return n.toLocaleString(navigator.language, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true
  });
};

export const nowLong = () =>
  new Date()
    .toLocaleString(navigator.language, {
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      day: "2-digit",
      weekday: "short",
      // timeZoneName: "short",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    .replace(/,/g, "");

const nonCEChars = [
  // These make up the non "cavempt" characters. 0 appears more times as a quick way to make it occur more frequetly.
  "0",
  "0",
  "0",
  " "
];

export const genCavemptWithLen = (len = 24) => {
  const stringToShow = "cavempt".split("");

  // Need to pick out the fields that we'll use for our letters.
  const magicIndices = [];

  let i = 0;
  while (i < stringToShow.length) {
    const index = Math.floor(Math.random() * len);
    if (magicIndices.indexOf(index) == -1) {
      magicIndices.push(index);
      i++;
    }
  }

  // Now create a new string of random letters, except for the magic indices which will show the selected letters.
  let tmpMessage = "";
  for (i = 0; i < len; i++) {
    if (magicIndices.indexOf(i) >= 0) {
      tmpMessage = tmpMessage + stringToShow.shift();
    } else {
      const randomChar =
        nonCEChars[Math.floor(Math.random() * nonCEChars.length)];
      tmpMessage = tmpMessage + randomChar;
    }
  }
  return tmpMessage;
};

export const readDeviceOrientation = () => {
  if (Math.abs(window.orientation) === 90) {
    // Landscape
    return "landscape";
  } else {
    // Portrait
    return "portrait";
  }
};

const pad = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const longDate = ce => {
  let n = new Date(ce.Ts * 1000 + ce.Location.TzOffset * 1000);
  const day = n.getDate();
  const dayName = dayNames[day];
  const month = monthNames[n.getMonth()];
  return `${dayName} ${month} ${pad(day, 2)} ${n.getFullYear()} ${pad(
    n.getHours(),
    2
  )}:${pad(n.getMinutes(), 2)}:${pad(n.getSeconds(), 2)} ${
    ce.Location.TimeZone
  }`;
};

export const shortDate = ce => {
  let n = new Date(ce.Ts * 1000 + ce.Location.TzOffset * 1000);
  return n.toLocaleString(navigator.language, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true
  });
};

export const postSubscribe = email => {
  var xhr = new XMLHttpRequest();
  var url = "/cm-subscribe";
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4 && xhr.status === 200) {
      JSON.parse(xhr.responseText);
    }
  };
  var data = JSON.stringify({ EmailAddress: email });
  xhr.send(data);
};

export const easeOutQuint = x => {
  return 1 - Math.pow(1 - x, 5);
};

export const easeInOutQuart = x => {
  return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
};

export const easeInOutQuad = x => {
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
};

export const easeOutQuart = x => {
  return 1 - Math.pow(1 - x, 4);
};
