import axios from "axios";

const apiClient = () => {
  const hostname = location.hostname;
  return axios.create({
    baseURL:
      hostname == "localhost" ? `//${hostname}:9999/api` : `//${hostname}/api`
  });
};

const isIOS = () => {
  let userAgent = window.navigator.userAgent;
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    // iPad or iPhone
    return true;
  } else {
    // Anything else
    return false;
  }
};

export default {
  registerToken: ({ deviceId, userId, clientId }) =>
    apiClient().post(`/token/register?ios=0`, {
      deviceId,
      userId,
      clientId
    }),
  uploadFrame: (fileName, me, width, height) => {
    const data = new FormData();
    data.append("fileName", fileName);
    data.append("msg", JSON.stringify({ ...me, Type: "history" }));
    data.append("waveform", JSON.stringify({ data: [] }));
    data.append("width", width)
    data.append("height", height)

    return apiClient().post("/upload-frame", data, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`
      },
      timeout: 30000
    });
  },
  getImages: (pageNum, perPage = 50, daysAgo = false, omitWf = false) => {
    const hostname = location.hostname;
    const client = axios.create({
      baseURL:
        hostname == "localhost" ? `//${hostname}:9999/api` : `//${hostname}/api`
    });
    const params = {
      page: pageNum,
      perPage: perPage
    };
    if (daysAgo) params["daysAgo"] = 7;
    if (omitWf) params["omitWf"] = true;
    return client.get("/images", { params: params }).then(res => res.data);
  },
  getFrame: frameId => {
    const hostname = location.hostname;
    const client = axios.create({
      baseURL:
        hostname == "localhost" ? `//${hostname}:9999/api` : `//${hostname}/api`
    });
    const params = {
      image: frameId
    };
    return client.get("/frame", { params: params }).then(res => res.data);
  },
  getLatestFrame: () => {
    const hostname = location.hostname;
    const client = axios.create({
      baseURL:
        hostname == "localhost" ? `//${hostname}:9999/api` : `//${hostname}/api`
    });
    return client.get("/frame/latest").then(res => res.data);
  }
};
