<template>
  <section class="video-grid">
    <streaming-service @new-frame="fetchNewHistory" />
    <img class="logo new" src="/assets/ce-logo-2024.png" alt="">
    <div class="grid-wrapper">
      <div class="col" v-for="(col, i) in buckets" :key="i">
        <template v-for="i in col">
          <frame 
            v-if="i.fileName"
            :key="i.id"  
            :file="i.fileName"
            :height="i.Height"
            :width="i.Width"
            @click.native="setFeaturedVideo(i)"
          />
          <streamer-frame v-else :streamer="i" />
        </template>
      </div>
    </div>
    <transition name="fade">
      <div v-if="featuredVideo" class="featured-vid" @click.self="setFeaturedVideo(false)">
        <div class="stage">
          <div class="close" @click="setFeaturedVideo(false)">&#215;</div>
          <video 
            controls 
            autoplay 
            playsinline 
            loop 
            :style="{height: `${featuredVideoHeight}px`, width: `${featuredVideoWidth}px`}"
            :poster="`${posterUrlBase}${featuredVideo.fileName}/${featuredVideo.fileName}-00001.png`"
            :src="`${videoUrlBase}${featuredVideo.fileName}/${featuredVideo.fileName}.mp4`">
          </video>
          <div class="meta">
            <div class="date"> {{ featuredDate }}</div>
            <div class="copy-link" @click="copyLink" v-text="copyText" />
            <!-- <svg v-show="canShare" @click="shareVideo" class="share-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 88 90"><g id="Regular-S" transform="translate(0 82)"><path class="monochrome-0 hierarchical-0:secondary SFSymbolsPreview4D4D4D" d="M22.2168 5.46875L66.0156 5.46875C74.3652 5.46875 78.4668 1.36719 78.4668-6.83594L78.4668-44.3848C78.4668-52.5879 74.3652-56.6895 66.0156-56.6895L55.5176-56.6895L55.5176-49.6094L65.918-49.6094C69.3848-49.6094 71.4355-47.7051 71.4355-44.043L71.4355-7.17773C71.4355-3.4668 69.3848-1.61133 65.918-1.61133L22.3145-1.61133C18.7988-1.61133 16.7969-3.4668 16.7969-7.17773L16.7969-44.043C16.7969-47.7051 18.7988-49.6094 22.3145-49.6094L32.7148-49.6094L32.7148-56.6895L22.2168-56.6895C13.916-56.6895 9.76562-52.5879 9.76562-44.3848L9.76562-6.83594C9.76562 1.36719 13.916 5.46875 22.2168 5.46875Z"></path><path class="monochrome-1 hierarchical-1:primary SFSymbolsPreview212121" d="M44.0918-24.8047C45.9961-24.8047 47.6074-26.3672 47.6074-28.2227L47.6074-66.3086L47.3145-72.168L49.3652-69.9219L54.7363-64.1113C55.3711-63.4277 56.25-63.0859 57.0801-63.0859C58.9355-63.0859 60.3027-64.3555 60.3027-66.1133C60.3027-67.0898 59.9121-67.7734 59.2285-68.4082L46.6309-80.4688C45.752-81.3477 45.0195-81.6406 44.0918-81.6406C43.2129-81.6406 42.4805-81.3477 41.5527-80.4688L28.9551-68.4082C28.3203-67.7734 27.9297-67.0898 27.9297-66.1133C27.9297-64.3555 29.248-63.0859 31.1035-63.0859C31.9336-63.0859 32.8613-63.4277 33.4961-64.1113L38.8672-69.9219L40.918-72.168L40.625-66.3086L40.625-28.2227C40.625-26.3672 42.2363-24.8047 44.0918-24.8047Z"></path></g></svg> -->
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>

import api from "../lib/api.js";
import frame from "../components/Frame.vue"
import streamerFrame from "../components/StreamerFrame.vue"

import streamingService from "../components/StreamingService.vue";

export default {
  components: {
    frame,
    streamingService,
    streamerFrame
  },
  data() {
    return {
      images: [],
      paginationData: {},
      imagesLoaded: false,
      featuredVideo: false,
      videoUrlBase: "https://d1lgl9py4lz5jl.cloudfront.net/",
      posterUrlBase: "https://d5i40nk2yn9a8.cloudfront.net/",
      featuredVideoHeight: 0,
      featuredVideoWidth: 0,
      winWidth: 0,
      hasMorePages: true,
      fetchingMore: false,
      perPage: window.innerWidth < 600 ? 50 : 75,
      copyText: "Share",
      copyingText: false
    }
  },
  watch: {
    featuredVideo(video) {
      if (video) {
        document.body.classList.add('locked')
        this.sizeFeaturedVideo()
      }
      else document.body.classList.remove('locked')
    }
  },
  computed: {
    streamers() {
      return this.$store.state.streamers || []
    },
    canShare() {
      return !!navigator.share
    },
    isMobile() {
      return this.winWidth < 600
    },
    featuredLocation() {
      if (this.featuredVideo) return `${this.featuredVideo.city}, ${this.featuredVideo.countryCode}`
    },
    featuredDate() {
      if (this.featuredVideo) {
        const date = new Date(this.featuredVideo.timeStamp * 1000)
        // const options = {
        //   dateStyle: "short",
        // }
        const options = {
          month: "long",
          day: "numeric"
        }
        return date.toLocaleDateString("en-US", options)
      }
      else return false
    },
    buckets() {
      this.streamers.length
      const items = this.streamers.concat(this.images)
      const numBuckets = this.isMobile ? 2 : 4
      const buckets = []
      for (let i = 0; i < numBuckets; i++) buckets.push([])
      for (let i = 0; i < items.length; i++) {
        buckets[i % numBuckets].push(items[i])
      }
      return buckets
    }
  },
  created() { 
    api.getImages(1, this.perPage).then(res => {
      this.images = res.images.filter(i => i.fileName.split(".").length == 1)
      this.paginationData = res.pagintion
      this.hasMorePages = this.paginationData.total > this.paginationData.page * this.paginationData.perPage
      this.$nextTick(() => {
        // window.addEventListener('scroll', this.scrollHandler)
      })
    })
    this.winWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.winWidth = window.innerWidth
      if (this.featuredVideo) this.sizeFeaturedVideo()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.scrollHandler)
  },
  methods: {
    copyLink() {
      if (this.copyingText) return
      this.copyingText = true
      navigator.clipboard.writeText(`${this.videoUrlBase}${this.featuredVideo.fileName}/${this.featuredVideo.fileName}.mp4`);
      this.copyText = "Copied!"
      setTimeout(() => {
        this.copyText = "Share"
        this.copyingText = false
      }, 3000)
    },
    shareVideo() {
      navigator.share({
        url: `${this.videoUrlBase}${this.featuredVideo.fileName}/${this.featuredVideo.fileName}.mp4`,
        title: "C.E 000"
      })
    },
    scrollHandler() {
      if (!this.hasMorePages) return
      const percentScrolled = (document.body.scrollTop + window.innerHeight) / document.body.offsetHeight
      if (percentScrolled > .9 && !this.fetchingMore) this.fetchMore()
    },
    fetchNewHistory() {
      api.getImages(1, 1).then(res => {
        this.images.unshift(res.images[0])
      })
    },
    fetchMore() {
      this.fetchingMore = true
      api.getImages(this.paginationData.page + 1, this.perPage).then(res => {
        this.images = this.images.concat(res.images.filter(i => i.fileName.split(".").length == 1))
        this.paginationData = res.pagintion
        this.hasMorePages = this.paginationData.total > this.paginationData.page * this.paginationData.perPage
        this.$nextTick(() => {
          this.fetchingMore = false
        })
      })
    },
    setFeaturedVideo(video) {
      this.featuredVideo = video
    },
    sizeFeaturedVideo() {
      const aspectRatio = this.featuredVideo.Height / this.featuredVideo.Width
      // const heightPadding = this.isMobile ? 140: 360
      // const widthPadding = this.isMobile ? 80: 280
      const heightPadding = 60
      const widthPadding = 80
      const tall = this.featuredVideo.Height > this.featuredVideo.Width
      if (tall) {
        if (this.featuredVideo.Height > window.innerHeight - 50 - heightPadding) {
          this.featuredVideoHeight = window.innerHeight - 50 - heightPadding
          this.featuredVideoWidth = this.featuredVideoHeight / aspectRatio
        }
        else if (this.featuredVideo.Width > window.innerWidth - widthPadding) {
          this.featuredVideoWidth = window.innerWidth - widthPadding
          this.featuredVideoHeight = aspectRatio * this.featuredVideoWidth
        }
        else {
          this.featuredVideoWidth = this.featuredVideo.Width
          this.featuredVideoHeight = this.featuredVideo.Height
        }
      }
      else {
        if (this.featuredVideo.Width > window.innerWidth - widthPadding) {
          this.featuredVideoWidth = window.innerWidth - widthPadding
          this.featuredVideoHeight = aspectRatio * this.featuredVideoWidth
        }
        else if (this.featuredVideo.Height > window.innerHeight - 50 - heightPadding) {
          this.featuredVideoHeight = window.innerHeight - 50 - heightPadding
          this.featuredVideoWidth = this.featuredVideoHeight / aspectRatio
        }
        else {
          this.featuredVideoWidth = this.featuredVideo.Width
          this.featuredVideoHeight = this.featuredVideo.Height
        }
      }
    },
  }
}
</script>

<style lang="scss">

.video-grid {
  position: relative;
  min-height: 100vh;
  padding: 2px;
  box-sizing: border-box;
  .logo {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    padding: 10px;
    background-color: #000;
    box-sizing: border-box;
    z-index: 100;
  }
  .grid-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    .col {
      width: 50%;
      .frame {
        margin: 0;
        width: 100%;
        padding: 4px;
        mix-blend-mode: difference;
      }
    }
  }
  .featured-vid {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
    cursor: pointer;
    .close {
      display: none;
    }
    .stage {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      background: #000;
      text-align: center;
      max-width: calc(100% - 40px);
      min-width: 260px;
      max-height: calc(100% - 40px);
      padding: 20px 20px 40px;
      color: #fff;
      .meta {
        position: absolute;
        // bottom: 50px;
        left: 50%;
        width: 100%;
        transform: translate(-50%, 50%);
        font-weight: bold;
        bottom: 20px;
        font-size: 20px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        .date {
          font-size: 14px;
        }
        .share-button {
          // position: absolute;
          // right: 20px;
          height: 26px;
          // bottom: 1px;
          width: auto;
          cursor: pointer;
          // margin-left: 10px;
          margin-top: -4px;
        }
        .copy-link {
          font-size: 14px;
          display: inline-block;
          cursor: pointer;
        }
      }
    }
    video {
      position: relative;
      object-fit: contain;
      object-position: center;
      box-sizing: content-box;
      // border-radius: 125px;
      max-width: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    .logo {
      width: calc(100% - 20px);
      bottom: 10px;
    }
    .grid-wrapper {
      .col {
        width: 100%;
        .frame {
          padding: 2px;
        }
      }
    }
    .featured-vid {
      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
        display: block;
        font-size: 46px;
        height: 23px;
        line-height: .5;
      }
      .stage {
        max-width: calc(100% - 40px);
        max-height: calc(100% - 40px);
        width: calc(100% - 20px);
        min-width: unset;
        .meta {
          font-size: 18px;
          .share-button {
            bottom: -1px;
            height: 24px;
          }
        }
      }
      video {
        max-width: 100%;
      }
    }
  }
}

</style>