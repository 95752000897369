<template>
  <div :class="['loading-zig', {'hovering': isHovering}]" id="canvas" @click="startStream"></div>
</template>

<script>

let p5Runtime = false

export default {
  data() {
    return {
      startTime: Date.now() - 500,
      isHovering: false,
      p: false,
      isMobile: window.innerWidth < 500,
      zigSegmentLength: 0
    }
  },
  computed: {
    zigWidth() {
      return this.zigSegmentLength * 6
    },
    curveFactor() {
      return this.zigSegmentLength / 3.5
    },
    canvasScaling() {
      // return this.isMobile ? 10 : 4
      return 1
    },
    widthSegment() {
      return this.zigSegmentLength
    },
    heightSegment() {
      return this.zigSegmentLength * .88
    },
    lineHeight() {
      return this.isMobile ? 3 : 4
    },
    zigStrokeWidth() {
      return this.isMobile ? 6 : 8
    },
    triangleStrokeWidth() {
      return this.isMobile ? 12 : 16
    }
  },
  mounted() {
    this.zigSegmentLength = this.getZigSegmentLength()
    this.initP5()
  },
  beforeDestroy() {
    if (p5Runtime) p5Runtime.remove()
  },
  methods: {
    getZigSegmentLength() {
      const winWidth = window.innerWidth / this.canvasScaling
      const winHeight = window.innerHeight / this.canvasScaling
      let out
      if (this.isMobile) out = (winWidth - 20) / 6
      else out = (winWidth / 1.5) / 6
      out > 150 ? out = 150 : 0
      let segmentLength = Math.round(out / this.lineHeight) * this.lineHeight
      const zigHeight = segmentLength * .88 * 5
      if (zigHeight > winHeight - 40) {
        if (this.isMobile) out = (winHeight - 20) / 6
        else out = (winHeight / 1.5) / 6
        out > 150 ? out = 150 : 0
        segmentLength = Math.round(out / this.lineHeight) * this.lineHeight
      }
      return segmentLength
    },
    startStream() {
      if (this.isHovering) this.$emit('start')
    },
    initP5() {

      const drawZig = (p, curveFactor, fillColor = "#000") => {

        const xOffset = (p.width / 2) - (this.widthSegment * 3)
        const yOffset = (p.height / 2) - (this.heightSegment * 2.5)
        const wallHeight = this.heightSegment * 3

        p.strokeWeight(this.zigStrokeWidth)
        p.strokeCap(p.SQUARE)
        p.stroke(this.isHovering ? "red": "#ffffff")
        p.fill(fillColor)
        p.beginShape()

        p.vertex(xOffset - this.zigStrokeWidth / 2, p.height - yOffset)
        p.vertex(xOffset + this.widthSegment * 6, p.height - yOffset)
        p.vertex(xOffset + this.widthSegment * 6, p.height - wallHeight - yOffset + curveFactor + 10)
        p.bezierVertex(xOffset + this.widthSegment * 6, p.height - wallHeight - yOffset + curveFactor, xOffset + this.widthSegment * 6, p.height - wallHeight - yOffset, xOffset + this.widthSegment * 6 - curveFactor, p.height - wallHeight - yOffset)
        p.vertex(xOffset + this.widthSegment * 5, p.height - wallHeight - yOffset)
        p.vertex(xOffset + this.widthSegment * 5, p.height - this.heightSegment * 4 - yOffset + curveFactor)
        p.bezierVertex(xOffset + this.widthSegment * 5, p.height - this.heightSegment * 4 - yOffset + curveFactor, xOffset + this.widthSegment * 5, p.height - this.heightSegment * 4 - yOffset, xOffset + this.widthSegment * 5 - curveFactor, p.height - this.heightSegment * 4 - yOffset)
        p.vertex(xOffset + this.widthSegment * 4, p.height - this.heightSegment * 4 - yOffset)
        p.vertex(xOffset + this.widthSegment * 4, p.height - this.heightSegment * 5 - yOffset + curveFactor)
        p.bezierVertex(xOffset + this.widthSegment * 4, p.height - this.heightSegment * 5 - yOffset + curveFactor, xOffset + this.widthSegment * 4, p.height - this.heightSegment * 5 - yOffset, xOffset + this.widthSegment * 4 - curveFactor, p.height - this.heightSegment * 5 - yOffset)
        p.vertex(xOffset + this.widthSegment * 2 + curveFactor, p.height - this.heightSegment * 5 - yOffset)
        p.bezierVertex(xOffset + this.widthSegment * 2 + curveFactor, p.height - this.heightSegment * 5 - yOffset, xOffset + this.widthSegment * 2, p.height - this.heightSegment * 5 - yOffset, xOffset + this.widthSegment * 2, p.height - this.heightSegment * 5 - yOffset + curveFactor)
        p.vertex(xOffset + this.widthSegment * 2, p.height - this.heightSegment * 4 - yOffset)
        p.vertex(xOffset + this.widthSegment + curveFactor, p.height - this.heightSegment * 4 - yOffset)
        p.bezierVertex(xOffset + this.widthSegment + curveFactor, p.height - this.heightSegment * 4 - yOffset, xOffset + this.widthSegment, p.height - this.heightSegment * 4 - yOffset, xOffset + this.widthSegment, p.height - this.heightSegment * 4 - yOffset + curveFactor)
        p.vertex(xOffset + this.widthSegment, p.height - wallHeight - yOffset)
        p.vertex(xOffset + curveFactor, p.height - wallHeight - yOffset)
        p.bezierVertex(xOffset + curveFactor, p.height - wallHeight - yOffset, xOffset, p.height - wallHeight - yOffset, xOffset, p.height - wallHeight - yOffset + curveFactor)
        p.vertex(xOffset, p.height - yOffset)
        p.endShape()
      }

      const drawArrow = (p, time, fill) => {

        const zigWidth = this.widthSegment * 6

        const triangleHeight = this.heightSegment * 2.5
        const triangleWidth = triangleHeight * .75
        const rectWidth = zigWidth - triangleHeight
        const arrowWidth = triangleHeight + rectWidth

        const xOffset = (p.width / 2) - (this.widthSegment * 3) + zigWidth + ((time % ((zigWidth + arrowWidth))) * -1)
        const yOffset = (p.height / 2) - (this.heightSegment * 2.5) + (this.heightSegment * 3.25)


        p.fill(fill)
        p.noStroke()
        p.strokeCap(p.SQUARE)
        p.strokeWeight(this.triangleStrokeWidth)
        p.stroke(this.isHovering ? "red": "#ffffff")
        p.beginShape()

        p.vertex(xOffset, yOffset)
        p.vertex(xOffset + triangleWidth, yOffset - triangleHeight / 2)
        // Rect
        p.vertex(xOffset + triangleWidth, yOffset - triangleHeight / 2 + triangleHeight / 3)
        p.vertex(xOffset + triangleWidth + rectWidth, yOffset - triangleHeight / 2 + triangleHeight / 3)
        p.vertex(xOffset + triangleWidth + rectWidth, yOffset - triangleHeight / 2 + triangleHeight / 3 + triangleHeight / 3)
        p.vertex(xOffset + triangleWidth, yOffset - triangleHeight / 2 + triangleHeight / 3 + triangleHeight / 3)

        p.vertex(xOffset + triangleWidth, yOffset + triangleHeight / 2)
        p.vertex(xOffset, yOffset)
        p.endShape()

      }

      const config = p => {
        
        p.windowResized = () => {
          p.resizeCanvas(p.windowWidth / this.canvasScaling, this.$el.offsetHeight);
          this.isMobile = p.windowWidth < 500
          this.zigSegmentLength = this.getZigSegmentLength()
        }

        p.setup = () => {
          p.pixelDensity(1);
          p.createCanvas(p.windowWidth / this.canvasScaling, this.$el.offsetHeight, p.WEBGL)
          // p.frameRate(60)
          p.setAttributes('antialias', false);

          p5Runtime = p
        }
        p.draw = () => {
          
          const zigHeight = this.heightSegment * 5
          const zigWidth = this.widthSegment * 6
          const arrowWidth = (this.heightSegment * 2.5) + (this.widthSegment * 3)
          const xOffset = (p.width / 2) - (this.widthSegment * 3)
          const yOffset = (p.height / 2) - (this.heightSegment * 2.5)

          p.translate(-p.width/2,-p.height/2,0)
          p.noSmooth()
          this.isHovering = p.mouseX > xOffset && p.mouseX < xOffset + zigWidth && p.mouseY > yOffset && p.mouseY < yOffset + zigHeight
          p.background(0)
          p.clip(() => drawZig(p, this.curveFactor, "#000000"))

          const now = Date.now()
          const time = now - this.startTime

          // Draw lines
          const lineTimeModifier = this.isMobile ? 40 : 40
          const lineTime = (time / lineTimeModifier)
          p.fill(this.isHovering ? "red": "#ffffff")
          p.noStroke()
          for (let i = -1; i < Math.round(zigHeight / this.lineHeight); i++) {
            const yPos = (yOffset + zigHeight - (((i * this.lineHeight) + lineTime)) % (zigHeight + this.lineHeight))
            if (i % 2) p.rect(xOffset, yPos , zigWidth, this.lineHeight)
          }

          const arrowTimeModifier = this.isMobile ? 12 : 6
          const arrowTime = time / arrowTimeModifier
          drawArrow(p, arrowTime, "#000")
          drawArrow(p, arrowTime + (zigWidth + arrowWidth) / 2 + (zigWidth - arrowWidth) / 2, "#000")

        }
      }

      new p5(config, 'canvas')
    }
  }
}

</script>

<style lang="scss">

.loading-zig {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // z-index: 500;
  .teaser {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    z-index: 100;
    font-size: 64px;
    font-weight: bold;
  }
  canvas {
    // width: 100vw !important;
    // height: 100vh !important;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  &.hovering {
    cursor: pointer;
  }
  @media only screen and (max-width: 450px) {
    .teaser {
      font-size: 32px;
    }
  }
}

</style>