export const downSample = `

  precision mediump float;

  // x,y coordinates, given from the vertex shader
  varying vec2 vTexCoord;

  // the canvas contents, given from filter()
  uniform sampler2D tex0;
  // other useful information from the canvas
  uniform vec2 canvasSize;

  void main() {
    float scale = 2.;
    vec2 texelSize = vec2(1.)/(canvasSize.xy/scale);
    vec2 uv = vTexCoord;
    //https://www.opengl.org/discussion_boards/showthread.php/164213-3D-linear-filtering
    vec4 tl = texture2D(tex0, uv);
    vec4 tr = texture2D(tex0, uv + vec2(texelSize.x, 0.0));
    vec4 bl = texture2D(tex0, uv + vec2(0.0, texelSize.y));
    vec4 br = texture2D(tex0, uv + vec2(texelSize.x, texelSize.y));
    vec2 f = fract( uv * (canvasSize.xy/scale));
    vec4 tA = mix( tl, tr, f.x );
    vec4 tB = mix( bl, br, f.x );
    gl_FragColor = mix( tA, tB, f.y );
  }
`

export const glitchShader = `

  precision mediump float;

  // x,y coordinates, given from the vertex shader
  varying vec2 vTexCoord;

  // the canvas contents, given from filter()
  uniform sampler2D tex0;
  // other useful information from the canvas
  uniform vec2 canvasSize;


  void main() {
    vec2 uv = vTexCoord;
    uv.x = 1.0 - uv.x;
    // vec2 uv = fragCoord.xy / iResolution.xy;
    //    uv.t = 1.0 - uv.t;
    
    float x = uv.s;
    float y = uv.t;
    
    //
    float glitchStrength = 3.5;
    
    
    
    // get snapped position
    float psize = 0.05 * glitchStrength;
    float psq = 1.0 / psize;

    float px = floor( x * psq + 0.5) * psize;
    float py = floor( y * psq + 0.5) * psize;
    
    // vec4 colSnap = texture( iChannel0, vec2( px,py) );

    vec4 colSnap = texture2D(tex0, vec2( px,py));
    
    float lum = pow( (colSnap.r + colSnap.g + colSnap.b) / 3.0, glitchStrength ); // remove the minus one if you want to invert luma
    
    
    
    // do move with lum as multiplying factor
    float qsize = psize * lum;
    
    float qsq = 1.0 / qsize;

    float qx = floor( x * qsq + 0.5) * qsize;
    float qy = floor( y * qsq + 0.5) * qsize;

    float rx = (px - qx) * lum + x;
    float ry = (py - qy) * lum + y;
    
    // vec4 colMove = texture( iChannel0, vec2( rx,ry) );
    vec4 colMove = texture2D(tex0, vec2( rx,ry));
    
    
    // final color
    gl_FragColor = colMove;
  }
` 

export const betterDitherShader = `

precision mediump float;

// x,y coordinates, given from the vertex shader
varying vec2 vTexCoord;

// the canvas contents, given from filter()
uniform sampler2D tex0;
// other useful information from the canvas
uniform vec2 canvasSize;

uniform float time;

#define PIXEL_SIZE 4.


bool getValue(float brightness, vec2 pos) {
    
    // do the simple math first
    if (brightness > 16.0/17.0) return false;
    if (brightness < 01.0/17.0) return true;
    
    vec2 pixel = floor(mod((pos.xy+0.5)/PIXEL_SIZE, 4.0));
    int x = int(pixel.x);
    int y = int(pixel.y);
    bool result = false;
    
    // compute the 16 values by hand, store when it's a match
    	 if (x == 0 && y == 0) result = brightness < 16.0/17.0;
   	else if (x == 2 && y == 2) result = brightness < 15.0/17.0;
   	else if (x == 2 && y == 0) result = brightness < 14.0/17.0;
   	else if (x == 0 && y == 2) result = brightness < 13.0/17.0;
   	else if (x == 1 && y == 1) result = brightness < 12.0/17.0;
   	else if (x == 3 && y == 3) result = brightness < 11.0/17.0;
   	else if (x == 3 && y == 1) result = brightness < 10.0/17.0;
   	else if (x == 1 && y == 3) result = brightness < 09.0/17.0;
   	else if (x == 1 && y == 0) result = brightness < 08.0/17.0;
   	else if (x == 3 && y == 2) result = brightness < 07.0/17.0;
   	else if (x == 3 && y == 0) result = brightness < 06.0/17.0;
    else if (x == 0 && y == 1) result =	brightness < 05.0/17.0;
   	else if (x == 1 && y == 2) result = brightness < 04.0/17.0;
   	else if (x == 2 && y == 3) result = brightness < 03.0/17.0;
   	else if (x == 2 && y == 1) result = brightness < 02.0/17.0;
   	else if (x == 0 && y == 3) result = brightness < 01.0/17.0;
        
	return result;
}

mat2 rot(in float a) {
	return mat2(cos(a),sin(a),-sin(a),cos(a));	
}

// main distance function
float de(vec3 p) {
    
    float de = 0.0;
    
    de += length(p) - 5.0;
    
    de += (sin(p.x*3.0424+time * 1.9318)*.5+.5)*0.3;
    de += (sin(p.y*2.0157+time * 1.5647)*.5+.5)*0.4;
    
	return de;
} 

// normal function
vec3 normal(vec3 p) {
	vec3 e = vec3(0.0, 0.001, 0.0);
	return normalize(vec3(
		de(p+e.yxx)-de(p-e.yxx),
		de(p+e.xyx)-de(p-e.xyx),
		de(p+e.xxy)-de(p-e.xxy)));	
}

void main() {

  vec2 texCoord = vTexCoord;

  texCoord.x = 1. - texCoord.x;

  vec2 fragCoord = texCoord * canvasSize;

  vec4 sample = texture2D(tex0, texCoord);
  float brightness = 1. - (0.2126*sample.x + 0.7152*sample.y + 0.0722*sample.z);

  if (brightness < 0.5) {
    brightness = 8. * brightness * brightness * brightness * brightness;
  }
  else {
    brightness = 1. - pow(-2. * brightness + 2., 4.) / 2.;
  }

  vec2 pos = fragCoord - canvasSize.xy * 0.5;

  bool power = getValue(brightness, pos);

  vec4 color = vec4(0.0);
  if (power) color = vec4(1.0, 0., 0., 1.0);
  // if (power) color = vec4(1.0);

  gl_FragColor = color;
}

`