import Vuex from 'vuex'
import Vue from "vue";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    audioCtx: false,
    audioId: "",
    streamers: [],
    words: {},
    userData: {},
    userConfirmed: false
  },
  mutations: {
    setAudioCtx (state, audio) {
      state.audioCtx = audio
    },
    setAudioId(state, id) {
      state.audioId = id
    },
    addStreamer(state, streamer) {
      state.streamers.push(streamer)
    },
    removeStreamer(state, uid) {
      const idx = state.streamers.findIndex(s => s.el.dataset.vidUid == uid);
      if (idx >= 0) state.streamers.splice(idx, 1);
    },
    setStreamerPosition(state, {uid, pos}) {
      const streamerIdx = state.streamers.findIndex(s => s.uid === uid)
      if (streamerIdx > -1) state.streamers[streamerIdx].position = pos
    },
    clearStreamerPositions(state) {
      for (let i = 0; i < state.streamers.length; i++) state.streamers[i].position = {
        x: 0,
        y: 0,
        col: -1,
        row: -1
      }
    },
    clearStreamers(state) {
      state.streamers = []
    },
    pushWord(state, update) {
      if (!state.words[update.uid])state.words[update.uid] = []
      if (update.transcript) state.words[update.uid] = state.words[update.uid].concat([update.transcript])
    },
    clearWords(state) {
      state.words = {}
    },
    clearStreamerWords(state, uid) {
      state.words[uid] = []
    },
    setUserData(state, data) {
      state.userData = data
    },
    setUserConfirmed(state, confirmed) {
      state.userConfirmed = confirmed
    }
  }
})

export default store