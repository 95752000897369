<template>
  <div :class="['page-home', {'active': startStream}, {'hovering': isHovering && !startStream}]">

    <!-- <img class="tagline" src="~/assets/c-and-e.svg" /> -->

    <img v-show="userConfirmed" class="spin-logo" src="~/assets/CE-Cube.png" alt="">

    <loading-zig v-if="!userConfirmed" @start="start" />

    <video-grid v-else />

    <!-- <streamer-canvas v-else /> -->
  </div>
</template>

<script>

// import ArrowGrid from "../components/ArrowGrid.vue"

import VideoGrid from "../components/VideoGrid.vue"
import loadingZig from "../components/loadingZig.vue"
import StreamerCanvas from "../components/StreamerCanvas.vue"

export default {
  components: {
    loadingZig,
    VideoGrid,
    StreamerCanvas
  },
  data() {
    return {
      startStream: false,
      isHovering: false
    }
  },
  computed: {
    userConfirmed() {
      return this.$store.state.userConfirmed
    }
  },
  mounted() {
  },
  methods: {
    toggleLogoHover(isHovering) {
      this.isHovering = isHovering
    },
    start() {
      this.startStream = true
      this.$store.commit('setUserConfirmed', true)
    }
  }
};
</script>

<style lang="scss">
@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

.page-home {
  position: relative;
  background-color: #fff;
  width: 100%;
  min-height: 100rvh;
  .spin-logo {
    position: fixed;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    width: 400px;
    height: auto;
    cursor: pointer;
    animation: rotate 20s linear infinite;
  }
  &.active {
    background-color: #000;
  }
  @media only screen and (max-width: 600px) {
    .spin-logo {
      width: 275px;
    }
  }
}
</style>
