import { initialString, wssProto, now, nowLong } from "../utils";
export const WebSocketMixin = {
  data() {
    return {
      WSHandler: null,
      pingInterval: null,
      connectionTimeout: null,
      history: null,
      lastRcv: null,
      lastSend: new Date(),
      uid: null,
      cavempt: null
    };
  },
  methods: {
    websocketSetup() {
      let url = "";
      if (location.port == "") {
        url = `${wssProto()}://${location.hostname}/ws`;
      } else {
        let port = location.port == "1234" ? 9999 : location.port;
        url = `${wssProto()}://${location.hostname}:${port}/ws`;
      }
      this.WSHandler = new WebSocket(url);

      this.WSHandler.addEventListener("open", () => {
        // eslint-disable-next-line no-console
        clearTimeout(this.connectionTimeout);
        this.pingInterval = setInterval(() => {
          this.WSHandler.send(
            JSON.stringify({ Type: "ping", Uid: this.uid, Created: now() })
          );
        }, 10000);
      });

      this.WSHandler.addEventListener("message", msg => {
        const data = JSON.parse(msg.data);
        // eslint-disable-next-line no-console
        if (data.Type == "cavempt") {
          this.handleCE(data);
        } else if (data.Type == "welcome") {
          this.onWelcome(data);
        } else if (data.Type == "peers") {
          this.onPeers(data);
        } else if (data.Type == "peerJoined") {
          this.onPeerJoined(data);
        } else if (data.Type == "peerLeft") {
          this.onPeerLeft(data);
        } else if (data.Type == "history") {
          const histMsgs = JSON.parse(data.Body);
          histMsgs.sort((a, b) => (a.Ts = b.Ts));
          this.history = histMsgs;
          histMsgs.slice(0, MAX_CVMTS).forEach(m => {
            this.handleCE(m);
          });
        } else if ( data.Type == "newWord") {
          this.$store.commit("pushWord", {uid: data.UID, transcript: data.Body})
        }
        else if ( data.Type == "newFrame") {
          this.$emit("new-frame")
        }
      });

      this.WSHandler.addEventListener("close", () => {
        // eslint-disable-next-line no-console
        clearTimeout(this.connectionTimeout);
        clearInterval(this.pingInterval);
      });
    },
    handleCE(msg) {
      const bodyParts = msg.Body.split("&&&");
      let isSpeaking = false;
      if (bodyParts.length > 1) {
        isSpeaking = bodyParts[1] === "true";
        msg.Body = bodyParts[0];
      }
      if (msg.UID == this.uid) {
        this.cavempt = Object.assign(msg, {
          Me: true,
          Created: now(),
          CreatedLong: nowLong()
        });
        this.didSendCe = true;
      } else {
        this.onPeerMessage(msg);
      }

      // add speaker to current video to add the green border
      this.markVideo(msg.UID, this.videoEls, isSpeaking);
    },
    onWelcome(msg) {
      this.uid = msg.UID;
      this.cavempt = Object.assign(msg, {
        Body: this.getInitCavString(),
        Created: now(),
        CreatedLong: nowLong()
      });
      this.$store.commit("setUserData", this.cavempt)
      document.querySelector(".connecting-container div").innerText =
        "connected to websocket...";
      // in parent component
      this.initBusEvents(); // STARTS VIDEO STREAMING
    },
    onPeers(msg) {
      let peers = JSON.parse(msg.Body);
      peers = peers.map(p => {
        p.Body = this.getInitCavString();
        p.CreatedLong = nowLong();
        p.created = now();
        return p;
      });
      this.wsPeers = peers;
    },
    onPeerJoined(msg) {
      const idx = this.wsPeers.findIndex(ce => ce.UID == msg.UID);
      if (idx == -1) {
        msg.Body = this.getInitCavString();
        this.wsPeers.push(msg);
      }
    },
    onPeerLeft(msg) {
      const idx = this.wsPeers.findIndex(ce => ce.UID == msg.UID);
      if (~idx) {
        this.wsPeers.splice(idx, 1);
        this.videoEventBus.$emit("peerLeft", msg);
      }
    },
    send(str, isSpeaking) {
      const n = new Date();
      this.WSHandler.send(
        JSON.stringify({
          Type: "cavempt",
          Body: `${str}&&&${isSpeaking}`,
          UserID: this.userID,
          Uid: this.uid,
          Created: now(),
          CreatedLong: nowLong()
        })
      );
      this.lastSend = n;
    },
    sendWord(word) {
      this.WSHandler.send(
        JSON.stringify({
          Type: "newWord",
          Body: word,
          UserID: this.userID,
          Uid: this.uid,
          Created: now(),
          CreatedLong: nowLong()
        })
      );
      this.lastSend = new Date();
    },
    getInitCavString() {
      return `${initialString()},${initialString(14)}`;
    }
  }
};
