<template>
  <header class="site-header">
    <router-link class="logo" to="/">
      <svg height="75" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.86 112.54">
        <!-- <rect style="fill:#000;" class="cls-1" width="117.86" height="112.54"></rect> -->
        <path style="fill:#fff;" class="cls-2"
          d="M528.19,259.67V245.05H514.08V229.51H477.5v15.54H462.81v14.62H447.24v61.54H544V259.67Zm-41.27-5V239.18h17.74v15.53h14.1v14.62h15.86v42.22H456.67V269.33h15.56V254.71Z"
          transform="translate(-436.71 -219.09)">
        </path>
      </svg>
    </router-link>
    <router-link target="_blank" to="/knowledge" class="history-link">knowledge</router-link>
    <div v-if="activeTape" class="now-playing">
      <div class="text">NOW PLAYING: {{ activeTape }}</div>
      <!-- <svg class="pause" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M11 22h-4v-20h4v20zm6-20h-4v20h4v-20z"/></svg> -->
      <svg v-if="!isPaused" @click="pauseAudio" class="pause" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" stroke-width="0" stroke="#000" fill="#000">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M6 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
        <path d="M14 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
      </svg>
      <svg v-else @click="playAudio" class="pause" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z" stroke-width="0" fill="currentColor" />
      </svg>
    </div>
    <tape-player @playing="setTape" />
  </header>
</template>

<script>

import TapePlayer from "./TapePlayer.vue"

export default {
  components: {
    TapePlayer,
  },
  data() {
    return {
      activeTape: "",
      isPaused: false
    }
  },
  watch: {
    audioId() {
      this.isPaused = false
    }
  },
  computed: {
    audioId() {
      return this.$store.state.audioId
    }
  },
  methods: {
    setTape(tape) {
      this.activeTape = tape
    },
    pauseAudio() {
      this.$store.state.audioCtx.pause()
      this.isPaused = true
    },
    playAudio() {
      this.$store.state.audioCtx.play()
      this.isPaused = false
    }
  }
}
</script>

<style lang="scss">


.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 300;

  .now-playing {
    position: absolute;
    top: 50%;
    left: 70px;
    transform: translateY(-50%);
    color: #000;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-family: 'helvetica';
    .text {
      margin-top: 2px;
    }
    .pause {
      margin-left: 2px;
      cursor: pointer;
    }
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
    display: flex;
    filter: invert(1);

    svg {
      height: 40px;
    }
  }

  .history-link {
    position: absolute;
    top: 50%;
    right: 88px;
    transform: translateY(-50%);
    color: #000;
    font-weight: bold;
    text-decoration: none;
    display: block;
    &:hover {
      text-decoration: underline;
    }
    &.router-link-active {
      display: none;
    }
  }

  @media only screen and (max-width: 450px) {
    // background-color: #000 !important;

    .now-playing {
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      left: 60px;
      top: 50%;
      font-size: 14px;
      // color: #fff !important;

      .text {
        transform: translateX(100%);
        animation: text-scroll 10s linear infinite;
      }
      .pause {
        display: none;
      }
    }

    .logo {
      left: 10px;
      transform: translateY(-50%);
      // filter: invert(0) !important;
    }
    .history-link {
      right: 108px;
    }
  }
}

@keyframes text-scroll {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}
</style>